<template>
  <div  style="display: inline">
    <sapn>{{text}}</sapn>
  </div>
</template>
<script setup>
import useFloorStore from "@/store/modules/floor";
import {ref} from "vue";
import {listFloor} from "@/api/system/floor";


const props = defineProps({
  floorId: {
    type: String,
    default: ""
  }
});

const text = ref('')

function getText(){
  let floorText = useFloorStore().getFloorList(props.floorId);
  if (floorText) {
    text.value = floorText;
  }else {
    listFloor().then((response) => {
      response.data.forEach(item => {
        useFloorStore().setFloorList(item.id, item.name)
      });
      text.value = useFloorStore().getFloorList(props.floorId);
    });
  }
}

watch(() => props.floorId,val =>{
  if (val) {
    getText()
  }
} , { immediate: true } )
</script>
