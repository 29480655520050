import useDictStore from '@/store/modules/dict'
import { getDicts } from '@/api/system/dict/data'

/**
 * 获取字典数据
 */
export function useDict(...args) {
  const res = ref({});
  return (() => {
    args.forEach((dictType, index) => {
      res.value[dictType] = [];
      const dicts = useDictStore().getDict(dictType);
      if (dicts) {
        res.value[dictType] = dicts;
      } else {
        getDicts(dictType).then(resp => {
          res.value[dictType] = resp.data.map(p => ({
            label: p.dictLabel,
            value: p.dictValue,
            elTagType: p.listClass,
            elTagClass: p.cssClass
          }))
          useDictStore().setDict(dictType, res.value[dictType]);
        })
      }
    })
    return toRefs(res.value);
  })()
}

export function useDictData(dictType)  {
  const dictData = ref([]);
  dictData.value = useDictStore().getDict(dictType);
    if (dictData.value) {
      return dictData.value ;
    } else {
      getDicts(dictType).then(res => {
        dictData.value = res.data.map(p => ({
          label: p.dictLabel,
          value: p.dictValue,
          elTagType: p.listClass,
          elTagClass: p.cssClass
        }))
        useDictStore().setDict(dictType, dictData.value);
      })
    }
    return dictData.value;
}



export const getDictOptions = (dictType) => {
  return useDictStore().getDict(dictType) || [];
};



export const getIntDictOptions = (dictType) => {
  // 获得通用的 DictDataType 列表
  const dictOptions = getDictOptions(dictType);
  // 转换成 number 类型的 NumberDictDataType 类型
  const dictOption = [];
  dictOptions.forEach((dict) => {
    dictOption.push({
      ...dict,
      value: parseInt(dict.value + '')
    });
  });
  return dictOption;
};

export const getStrDictOptions = (dictType) => {
  // 获得通用的 DictDataType 列表
  const dictOptions = getDictOptions(dictType);
  // 转换成 string 类型的 StringDictDataType 类型
  const dictOption = [];
  dictOptions.forEach((dict) => {
    dictOption.push({
      ...dict,
      value: dict.value + ''
    });
  });
  return dictOption;
};

export const getBoolDictOptions = (dictType) => {
  const dictOption = [];
  const dictOptions = getDictOptions(dictType);
  dictOptions.forEach((dict) => {
    dictOption.push({
      ...dict,
      value: dict.value + '' === 'true'
    });
  });
  return dictOption;
};

